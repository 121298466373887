<template>
  <div class="news-container">
    <HeaderComponent
      imageSrc="none"
      titleText="news_follow"
    />
    <div class="fb-embed-container">
      <iframe
        v-if="width > 1100"
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLietuvosMoteruDraugija&tabs=timeline&width=500&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
        width="500"
        height="1000"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
      <iframe
        class="mobile-frame"
        v-else
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLietuvosMoteruDraugija&tabs=timeline&width=340&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
        width="340"
        height="500"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "NewsView",
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  components: { HeaderComponent },
};
</script>

<style lang="scss" scoped>
.news-container {
  background-image: url("@/assets/images/header-images/faceboook-feed-BG-naujas.jpg");
  background-position: 0;
  background-position-x: -100px;
  background-size: cover;
}
.fb-embed-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  z-index: 91;
  padding-bottom: 64px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  iframe {
    border-radius: 10px;
    @media (max-width: 1100px) {
      height: 80vh;
    }
  }
  @media (max-width: 1100px) {
    margin-top: 0;
    margin-bottom: 32px;
  }
  @media (max-width: 370px) {
    margin-top: 36px;
  }
}
</style>
