<template>
  <div class="member-page">
    <HeaderComponent
      titleText="memb_title"
      imageSrc="naryste-BG.jpg"
    />
    <div class="membership-block">
      <span>International Council of Women (ICW)</span>
      <div class="image-container">
        <a target="_blank" href="https://www.icw-cif.com/">
          <img
            class="big"
            src="@/assets/images/sponsors/ICW-logo.jpg"
            alt="logo"
          />
      </a>
      </div>
    </div>
    <div class="membership-block">
      <span>European Centre of the International Council of Women (ECICW)</span>
      <div class="image-container">
        <a href="https://www.facebook.com/ecicw2020" target="_blank">
          <img class="smaller" src="@/assets/images/sponsors/ECICW-logo.jpg" alt="logo" />
        </a>
      </div>
    </div>
    <div class="membership-block">
      <span>Internation Alliance of Women (IAW)</span>
      <div class="image-container">
        <a target="_blank" href="https://www.womenalliance.org/">
          <img
            class="big"
            src="@/assets/images/sponsors/IAW-logo.jpg"
            alt="logo"
          />
        </a>
      </div>
    </div>
    <div class="membership-block">
      <span>Associated Country Women of the world</span>
      <div class="image-container">
        <a target="_blank" href="https://acww.org.uk/">
          <img
            class="small"
            src="@/assets/images/sponsors/ACWW-logo.jpg"
            alt="logo"
          />
        </a>
      </div>
    </div>
    <div class="membership-block">
      <span>{{ toLocal('memb_platform') }}</span>
      <div class="image-container">
        <a target="_blank" href="https://www.vbplatforma.org/LT">
          <img class="big" src="@/assets/images/sponsors/VBP.jpg" alt="logo" />
        </a>
      </div>
    </div>
    <div class="membership-block">
      <span>{{toLocal('memb_lmlo')}}</span>
      <div class="image-container">
        <a target="_blank" href="https://lmlo.lt/">
          <img
            class="big"
            src="@/assets/images/sponsors/LMLO-logo-pilnas.jpg"
            alt="logo"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default { components: { HeaderComponent } };
</script>

<style lang="scss" scoped>
.member-page {
  display: flex;
  flex-direction: column;
}

.membership-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 5%;
  &:not(:last-child) {
    border-bottom: 2px solid #f1e9e2;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 36px 3.5%;
  }
  span {
    text-align: start;
    width: 60%;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    @media (max-width: 1100px) {
      width: 80%;
      text-align: center;
    }
  }
  .image-container {
    width: 40%;
    display: flex;
    justify-content: start;
    a{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      @media(max-width: 1100px){
        justify-content: center;
      }
    }
    @media (max-width: 1100px) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    img {
      max-width: 75%;
      object-fit: scale-down;
      max-height: 122px;
      @media (max-width: 1100px) {
        margin-top: 24px;
        width: 100%;
        height: 100%;
        max-height: fit-content;
        object-fit: cover;
        &.small {
          max-height: 92px;
          max-width: 92px;
        }
        &.big {
          min-height: 20px;
          min-width: 80%;
        }
        &.smaller{
          max-width: 122px;
        }
      }
    }
  }
}
</style>
