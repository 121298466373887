<template>
  <div class="conference-view">
    <HeaderComponent
      :titleText="width < 1100 ? toLocal('nav_conferences') : toLocal('conf_title')"
      imageSrc="konferencijos-BG.jpg"
    />
    <div class="project-block">
      <div class="left">
        <span class="project-title single" v-html="toLocal('conf_politics')"
          ></span
        >
      </div>
    </div>
    <div class="project-block">
      <div class="left">
        <span class="project-title" v-html="toLocal('conf_pandemics')">
          </span
        >
        <span class="text">
          {{toLocal('conf_pandemics_desc')}}
        </span>
        <div class="btn-column">
          <a href="./10-21_programa_A5_1psl.pdf" download
            >{{toLocal('conf_download_flyer')}}</a
          >
          <a
            href="./Länderstudie-LITAUEN-en__Covid and Gender__22-09-08[1].pdf"
            download
            >{{toLocal('conf_download_graph')}}</a
          >
        </div>
      </div>
      <div class="right" v-if="width > 1100">
        <img src="@/assets/images/conferences/1/1.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/1/2.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/1/3.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/1/4.jpg" alt="conf_image" />
      </div>
      <div class="right-slider" v-else>
        <Swiper
          class="swiper"
          :freeMode="true"
          :slidesOffsetBefore="200"
          :loop="true"
          :slides-per-view="1"
          :space-between="0"
        >
          <SwiperSlide
            ><img src="@/assets/images/conferences/1/1.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/1/2.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/1/3.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/1/4.jpg" alt="conf_image"
          /></SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div class="project-block">
      <div class="left">
        <span class="project-title" v-html="toLocal('conf_empowerment')">
          </span
        >
        <div class="btn-column">
          <a href="./programa_a5.pdf" download>{{toLocal('conf_download_flyer')}}</a>
        </div>
      </div>
      <div class="right" v-if="width > 1100">
        <img src="@/assets/images/conferences/2/1.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/2/2.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/2/3.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/2/4.jpg" alt="conf_image" />
      </div>
      <div class="right-slider" v-else>
        <Swiper
          class="swiper"
          :freeMode="true"
          :slidesOffsetBefore="200"
          :loop="true"
          :slides-per-view="1"
          :space-between="0"
        >
          <SwiperSlide
            ><img src="@/assets/images/conferences/2/1.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/2/2.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/2/3.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/2/4.jpg" alt="conf_image"
          /></SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div class="project-block">
      <div class="left">
        <span class="project-title" v-html="toLocal('conf_turkey')">
          </span
        >
      </div>
      <div class="right" v-if="width > 1100">
        <img src="@/assets/images/conferences/3/1.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/3/2.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/3/3.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/3/4.jpg" alt="conf_image" />
      </div>
      <div class="right-slider" v-else>
        <Swiper
          class="swiper"
          :freeMode="true"
          :slidesOffsetBefore="200"
          :loop="true"
          :slides-per-view="1"
          :space-between="0"
        >
          <SwiperSlide
            ><img src="@/assets/images/conferences/3/1.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/3/2.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/3/3.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/3/4.jpg" alt="conf_image"
          /></SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div class="project-block">
      <div class="left">
        <span class="project-title" v-html="toLocal('conf_icw')"
          ></span
        >
        <span class="text">
          {{toLocal('conf_icw_desc')}}
        </span>
      </div>
      <div class="right" v-if="width > 1100">
        <img src="@/assets/images/conferences/4/1.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/4/2.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/4/3.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/4/4.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/4/5.jpg" alt="conf_image" />
        <img src="@/assets/images/conferences/4/6.jpg" alt="conf_image" />
      </div>
      <div class="right-slider" v-else>
        <Swiper
          class="swiper"
          :freeMode="true"
          :slidesOffsetBefore="200"
          :loop="true"
          :slides-per-view="1"
          :space-between="0"
        >
          <SwiperSlide
            ><img src="@/assets/images/conferences/4/1.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/4/2.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/4/3.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/4/4.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/4/5.jpg" alt="conf_image"
          /></SwiperSlide>
          <SwiperSlide
            ><img src="@/assets/images/conferences/4/6.jpg" alt="conf_image"
          /></SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";

export default {
  name: "ConferencesView",
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  components: { HeaderComponent, Swiper, SwiperSlide },
};
</script>

<style lang="scss" scoped>
.conference-view {
  display: flex;
  flex-direction: column;
}
.project-block {
  display: flex;
  padding: 40px 5%;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 40px 0;
  }
  &:not(:last-child) {
    border-bottom: 2px solid #f1e9e2;
  }
  .left {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-right: 60px;
    box-sizing: border-box;
    @media (max-width: 1100px) {
      width: 90%;
      padding: 28px;
    }
    .project-title {
      font-size: 20px;
      @media (max-width: 1100px) {
        font-size: 18px;
      }
      text-align: start;
      margin-bottom: 24px;
      &.single{
        margin-bottom: 0;
      }
    }
    .text {
      font-size: 16px;
      text-align: start;
    }
    .btn-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 40px;
      gap: 32px;
      @media (max-width: 1100px) {
        justify-content: center;
        align-items: center;
      }
      a {
        padding: 8px 28px;
        background: #f1e9e2;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        color: #000000;
        text-decoration: none;
        transition: 0.25s;
        &:hover {
          background: #cbb6a4;
        }
      }
    }
  }
  .right {
    width: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 24px;
    column-gap: 24px;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .right-slider {
    width: 100%;
    height: 90vw;
    .swiper {
      height: 90vw;
      width: 100%;
    }

    img {
      width: 90vw;
      height: 90vw;
      border-radius: 8px;
    }
    .slider-inner {
      display: flex;
      height: 240px;
      gap: 16px;
      img {
        border-radius: 8px;
      }
    }
  }
}
</style>
