<template>
  <div class="header-component">
    <img
      v-if="imageSrc != 'none'"
      :src="require('@/assets/images/header-images/' + imageSrc)"
      alt=""
    />
    <span class="header-title">{{ toLocal(titleText) }}</span>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    titleText: String,
    imageSrc: String,
  },
};
</script>

<style lang="scss" scoped>
.header-component {
  width: 100%;
  height: 480px;
  position: relative;
  @media (max-width: 1000px) {
    height: 60vw;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .header-title {
    position: absolute;
    top: 40%;
    z-index: 90;
    font-weight: 200;
    font-size: clamp(28px, 5vw, 60px);
    line-height: 92px;
    width: 100%;
    left: 0;
    text-align: center;
    @media (max-width: 1100px) {
      top: 45%;
      width: 90%;
      font-size: 28px;
      line-height: 44px;
      text-align: center;
      left: 5%;
    }
  }
}
</style>
