var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member-page"},[_c('HeaderComponent',{attrs:{"titleText":"memb_title","imageSrc":"naryste-BG.jpg"}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"membership-block"},[_c('span',[_vm._v(_vm._s(_vm.toLocal('memb_platform')))]),_vm._m(4)]),_c('div',{staticClass:"membership-block"},[_c('span',[_vm._v(_vm._s(_vm.toLocal('memb_lmlo')))]),_vm._m(5)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-block"},[_c('span',[_vm._v("International Council of Women (ICW)")]),_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":"https://www.icw-cif.com/"}},[_c('img',{staticClass:"big",attrs:{"src":require("@/assets/images/sponsors/ICW-logo.jpg"),"alt":"logo"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-block"},[_c('span',[_vm._v("European Centre of the International Council of Women (ECICW)")]),_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"href":"https://www.facebook.com/ecicw2020","target":"_blank"}},[_c('img',{staticClass:"smaller",attrs:{"src":require("@/assets/images/sponsors/ECICW-logo.jpg"),"alt":"logo"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-block"},[_c('span',[_vm._v("Internation Alliance of Women (IAW)")]),_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":"https://www.womenalliance.org/"}},[_c('img',{staticClass:"big",attrs:{"src":require("@/assets/images/sponsors/IAW-logo.jpg"),"alt":"logo"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-block"},[_c('span',[_vm._v("Associated Country Women of the world")]),_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":"https://acww.org.uk/"}},[_c('img',{staticClass:"small",attrs:{"src":require("@/assets/images/sponsors/ACWW-logo.jpg"),"alt":"logo"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":"https://www.vbplatforma.org/LT"}},[_c('img',{staticClass:"big",attrs:{"src":require("@/assets/images/sponsors/VBP.jpg"),"alt":"logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":"https://lmlo.lt/"}},[_c('img',{staticClass:"big",attrs:{"src":require("@/assets/images/sponsors/LMLO-logo-pilnas.jpg"),"alt":"logo"}})])])
}]

export { render, staticRenderFns }