<template>
  <div class="footerComponent">
    <div class="logo">
      <a :href="'/'+$store.state.lang.toLowerCase() + '/'">
      <img
        v-if="width > 1100 && $store.state.lang == 'LT'"
        src="@/assets/images/sponsors/_LMD-logo.png"
        alt="logo_icon"
      />
      <img
          v-else-if="width > 1100 && $store.state.lang == 'EN'"
          class="logo-mobile"
          src="@/assets/images/sponsors/_LMD-logo-en.png"
          alt="site_logo"
        />
      <img
        v-else
        src="@/assets/images/sponsors/_LMD-logo-pav.png"
        alt="logo_icon"
      />
      </a>
    </div>
    <div class="rights">
      <span>© {{toLocal('footer_copyright')}}</span>
    </div>
    <div class="contacts">
      <span v-if="width > 1100"><b>{{toLocal('footer_our_contacts')}}</b></span>
      <span
        ><a href="mailto:info@lmd.lt">
          <EmailSvg /><span>info@lmd.lt</span></a
        ></span
      >
      <span class="fb-link">
        <a
          href="https://www.facebook.com/LietuvosMoteruDraugija"
          target="_blank"
          ><FacebookMobileSvg /><span>
            {{toLocal('footer_fb')}}
          </span
          ></a
      ></span>
    </div>
  </div>
</template>

<script>
import FacebookMobileSvg from "@/assets/icons/facebook_mobile.svg?inline";
import EmailSvg from "@/assets/icons/email.svg?inline";

export default {
  name: "FooterComponent",
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
  },

  components: {
    EmailSvg,
    FacebookMobileSvg,
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
};
</script>

<style lang="scss" scoped>
.footerComponent {
  display: flex;
  min-height: 280px;
  //   min-height: 195px;
  background-image: url("@/assets/images/header-images/pradinis-page-BG.jpg");
  background-size: cover;
  background-position: 20%;
  padding-bottom: 30px;
  box-sizing: border-box;
  position: relative;
  padding: 40px 5%;
  @media (max-width: 1100px) {
    padding: 24px 28px;
    min-height: 195px;
    background-position: 40%;
  }
  .logo {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    height: 10%;
    img {
      width: 100%;
      max-width: 182px;
    }
    @media (max-width: 1100px) {
      width: 52px;
      img {
        width: 100%;
      }
    }
  }
  .rights {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 1100px) {
      position: absolute;
      width: 100%;
      height: auto;
      text-align: center;
      left: 0;
      bottom: 28px;
    }
  }
  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 80%;
    margin-top: 12px;
    width: 20%;
    gap: 24px;
    @media (max-width: 1100px) {
      margin-left: 30px;
      align-items: start;
      width: 70%;
    }
    @media (max-width: 380px) {
      margin-bottom: 40px;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        color: #000000;
        align-items: center;
        span{
          margin-left: 12px;
        }
        @media (max-width: 1100px) {
          span {
            margin-left: 12px;
            color: #000000;
            font-size: 16px;
            text-decoration: underline;
          }
        }
      }
      &.fb-link {
        svg {
          overflow: visible;
        }
        a {
          display: flex;
          text-decoration: none;
          span {
            text-align: start;
            margin-left: 12px;
            text-decoration: none;
            color: #000000;
          }
          @media (max-width: 1100px) {
            display: flex;
            margin-left: 0;
            white-space: nowrap;
            span {
              margin-left: 12px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
