<template>
  <div id="app">
    <NavBar />
    <router-view />
    <FooterComponent />
    <CookieModal/>
  </div>
</template>
<script>
import FooterComponent from "./components/FooterComponent.vue";
import CookieModal from "./components/CookieModal.vue";
import NavBar from "./components/Nav.vue";
export default {
  name: "App",
  components: {
    NavBar,
    FooterComponent,
    CookieModal
  },
  mounted(){
    console.log(this.$route.params.lang);
    if(this.$route.params.lang != undefined){
      this.$store.state.lang = this.$route.params.lang.toUpperCase();
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Poppins";
  src: url("@/assets/fonts/Poppins-Regular.ttf") format("truetype");
}
body {
  margin: 0;
}
#app {
  width: 100%;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin-top: 0;
  position: relative;
}
</style>
