<template>
  <div class="privacy-container">
    <div class="cookie-modal" v-if="!cookieAccepted && !privacyOpened">
      <div class="logo-container">
      </div>
      <div class="text-block">
        <span class="text-title">{{toLocal('cookies.used')}}</span>
        <span class="text-content"
          >{{toLocal('cookies.expl')}}</span
        ><br />
      </div>
      <div class="button-container">
        <button @click="acceptCookies">{{toLocal('cookies.allow_all')}}</button>
        <button @click="privacyOpened = true">{{toLocal('cookies.manage')}} ></button>
      </div>
    </div>
    <div
      class="cookie-big-modal"
      v-scroll-lock="privacyOpened"
      v-if="privacyOpened"
    >
      <div class="cookie-big-inside">
        <div class="big-header" :class="{ absolute: extraExpanded }">
          <div class="logo-container">
          </div>
          <div class="buttons-container">
            <div class="btn-row">
              <div class="btn-container">
                <span class="btn-title"
                  >{{toLocal('cookies.necessary')}}</span
                >
                <label class="switch">
                  <input
                    type="checkbox"
                    @change="(e) => setCookies(e, 'necessary')"
                  />
                  <span class="slider"></span>
                </label>
              </div>
              <transition name="slide">
                <span class="button-undertext" v-if="necessaryOpen">
                  These cookies are necessary for our website to function
                  properly and to protect against unusual activities. These
                  cookies are usually installed to respond to your actions and
                  requests, such as setting up privacy, logging in or filling
                  out forms.
                </span>
              </transition>
            </div>
            <div class="btn-row">
              <div class="btn-container">
                <span class="btn-title"
                  >{{toLocal('cookies.analytical')}}</span
                >
                <label class="switch">
                  <input
                    type="checkbox"
                    @change="(e) => setCookies(e, 'analytical')"
                  />
                  <span class="slider"></span>
                </label>
              </div>
              <transition name="slide">
                <span class="button-undertext" v-if="analyticalOpen"
                  >These cookies are often used to improve the performance of
                  our website, as they allow us to analyze the traffic, number
                  and sources of traffic of visitors. This type of cookie is not
                  strictly necessary for our website to work, but is intended,
                  for example, to let us know which pages are most interesting
                  to you. The information collected about you using analytical
                  cookies allows us to provide you with a personal and best
                  internet user experience. For example, thanks to these
                  cookies, we can ensure that the pages you visit are prepared
                  for a certain number of visitors and, accordingly, load
                  faster.
                </span>
              </transition>
            </div>
          </div>
          <div class="close-cross">
          </div>
        </div>
        <div
          class="big-content"
          :class="{ smaller: necessaryOpen || analyticalOpen }"
        >
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.title')}}</span>
            <span class="paragraph"
              >{{toLocal('cookies.p_1')}}
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold"
              >{{toLocal('cookies.p_2_title')}}</span
            >
            <span class="paragraph"
              >{{toLocal('cookies.p_2')}}
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.pg_2_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.pg_2')">
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_3_title')}}</span>
            <span class="paragraph"
              >{{toLocal('cookies.p_3_1')}}
            </span>
            <span class="paragraph" v-html="toLocal('cookies.p_3_2')">
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold"
              >{{toLocal('cookies.p_4_title')}}</span
            >
            <span class="paragraph" v-html="toLocal('cookies.p_4')">
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_5_title')}}</span>
            <span class="paragraph"
              >{{toLocal('cookies.p_5')}}
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold"
              >{{toLocal('cookies.p_6_title')}}</span
            >
            <span class="paragraph"
              >{{toLocal('cookies.p_6')}}
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_7_title')}}</span>
            <span class="paragraph"
              >{{toLocal('cookies.p_7_1')}}
            </span>
            <span class="paragraph" v-html="toLocal('cookies.p_7_2')"
              >
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_8_title')}}</span>
            <span class="paragraph"
              >{{toLocal('cookies.p_8')}}
            </span>
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_9_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.p_9')"
              ></span
            >
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_10_title')}}</span>
            <span class="paragraph"
              >{{toLocal('cookies.p_10')}}</span
            >
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_11_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.p_11')"
              ></span
            >
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_12_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.p_12')"
              ></span
            >
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_13_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.p_13')"
              ></span
            >
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_14_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.p_14')"
              ></span
            >
          </div>
          <div class="content-block">
            <span class="title semibold">{{toLocal('cookies.p_15_title')}}</span>
            <span class="paragraph" v-html="toLocal('cookies.p_15')"
              ></span
            >
          </div>
        </div>
        <div class="big-footer">
          <div class="btn-container">
            <button @click="setCookies(true, 'all')">
              {{toLocal('cookies.p_only_nes')}}
            </button>
            <button @click="setCookies(true, 'all')">{{toLocal('cookies.p_allow_all')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bootstrap } from "vue-gtag";

export default {
  data() {
    return {
      cookieAccepted: false,
      privacyOpened: false,
      necessaryOpen: false,
      analyticalOpen: false,
      extraExpanded: false,
    };
  },
  mounted() {
    this.cookieAccepted = this.$cookies.get("cookies_accepted");
    if (this.cookieAccepted) {
      this.enablePlugin();
    }
  },
  methods: {
    acceptCookies() {
      this.$cookies.set("cookies_accepted", true, "2y");
      this.cookieAccepted = true;
      this.enablePlugin();
    },
    enablePlugin() {
      bootstrap();
    },
    setCookies(e, type) {
      if (type == "analytical" && e.target.checked) {
        this.acceptCookies();
      } else if (type == "all" && e == true) {
        this.acceptCookies();
        this.privacyOpened = false;
      }
    },
    closePrivacy() {
      this.privacyOpened = false;
    },
  },
  watch: {
    necessaryOpen: {
      handler(newVal) {
        if (newVal == false) {
          if (!this.analyticalOpen) {
            setTimeout(() => {
              this.extraExpanded = false;
            }, 250);
          }
        } else {
          this.extraExpanded = true;
        }
      },
    },
    analyticalOpen: {
      handler(newVal) {
        if (newVal == false) {
          if (!this.necessaryOpen) {
            setTimeout(() => {
              this.extraExpanded = false;
            }, 250);
          }
        } else {
          this.extraExpanded = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);
  padding: 50px calc(min(160px, 10vw));
  min-height: 270px;
  z-index: 1000;
  font-size: 18px;
  color: #1e1826;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.logo-container {
  img {
    max-width: 150px;
    max-height: 70px;
    margin-right: 40px;
  }
}
.button-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  width: 50%;
  margin-top: 1rem;
  @media (max-width: 768px) {
    width: 90%;
  }
  button {
    display: flex;
    justify-content: center;
    color: white;
    background: #625a54;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    line-height: 24px;
    min-height: 40px;
    align-items: center;
    transition: 0.25s;
    &:first-child {
      margin-bottom: 25px;
    }
    &:last-child {
      background-color: #ffffff;
      color: #1e1826;
      /* main-dark */
      border: 1.5px solid #1e1826;
    }

    cursor: pointer;

    &:hover {
      color: white;
      background-color: #38322d;
    }
  }
}
a {
  cursor: pointer;
  color: #38322d;
  &:hover {
    text-decoration: underline;
  }
}
.text-block {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  .text-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 21px;
  }
  .text-content {
    font-size: 16px;
    line-height: 24px;
  }
}
.cookie-big-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  .cookie-big-inside {
    position: relative;
    background: #faf7f5;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 90vw;
    height: 90vh;
  }
}
.big-header {
  display: flex;
  width: 100%;
  position: relative;
  background: #faf7f5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  transition: 0.5s;
  height: fit-content;
  box-sizing: border-box;
  &.absolute {
    position: absolute;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .btn-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .btn-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .btn-title {
        display: flex;
        width: 10%;
        @media (max-width: 768px) {
          width: 50%;
        }
      }
      &:first-child {
        padding-bottom: 20px;
      }
    }
  }
}
.big-content {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: auto;
  height: 75%;
  padding: 15px 20px;
  @media (max-width: 768px) {
    max-height: 40vh;
    &.smaller {
      max-height: 70vh;
    }
  }
  &.smaller {
    height: 90%;
  }
  .content-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: start;
    .title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 5px;
    }
    .paragraph {
      margin: 10px 0;
    }
    .text-line {
      margin: 10px 0;
    }
    .content-table {
      border-collapse: collapse;
      th {
        border: 1px solid black;
        border-top: none;
        &:first-child {
          border-top: none;
          border-left: none;
        }
        &:last-child {
          border-right: none;
          border-top: none;
        }
      }
      td {
        word-wrap: break-word;
        border: 1px solid black;
        padding: 10px;
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
        &:first-child {
          th {
            &:not(.purpose) {
              min-width: 150px;
              width: 150px;
              max-width: 150px;
            }
          }
        }
      }
    }
  }
}
.big-footer {
  height: 10%;
  padding: 20px;
  box-sizing: border-box;
  background: #faf7f5;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 10px;
  @media (max-width: 768px) {
    height: 20vh;
  }
  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    button {
      max-width: 230px;
      height: 40px;
      min-width: 200px;
      border: none;
      cursor: pointer;
      @media (max-width: 768px) {
        width: 90%;
        max-width: 90%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 15px;
        min-height: 40px;
      }
      &:first-child {
        background: #ffffff;
        border: 1.5px solid #625a54;
        border-radius: 5px;
      }
      &:last-child {
        margin-left: 30px;
        background: #625a54;
        color: white;
        border-radius: 5px;
        transition: 0.25s;
        &:hover{
          color: white;
          background: #38322d;
        }
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #483a5b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #625a54;
}
input:focus + .slider {
  box-shadow: 0 0 1px #625a54;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}
.close-cross {
  cursor: pointer;
  @media (max-width: 768px) {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
.arrow-right {
  margin-right: 10px;
  cursor: pointer;
  transition: 0.5s;
  &.opened {
    transform: rotate(90deg);
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.slide-enter,
.slide-leave-to {
  transform-origin: 0px 0px;
  transform: scaleY(0);
}
</style>
