<template>
  <div class="home-page">
    <HeaderComponent
      titleText="home_lmd"
      imageSrc="pradinis-page-BG.jpg"
    />
    <div class="home-container">
      <!-- <div class="work-container">
        <span class="work-title">{{toLocal('home_job')}}</span>
        <span class="separator-underline"></span>
        <div class="flags"><LtFlagSvg /><UaFlagSvg /></div>
        <span class="text-separated">{{toLocal('home_together')}}</span>
        <span class="text-separated"
          ><b
            >{{toLocal('home_write_us')}}
            <a class="mail-link" href="mailto:info@lmd.lt">info@lmd.lt</a></b
          ></span
        >
        <div class="brown-btn-container" @click="gotoProjects">
          <span>{{ toLocal('home_more_about') }}</span>
          <span class="arrow"><ArrowRightSvg /></span>
        </div>
      </div> -->
    </div>
    <div class="info-container">
      <span class="info-title">{{toLocal('home_who')}}</span>
      <span class="info-text" v-html="toLocal('home_who_one')"
        ></span
      >
      <span class="info-text" v-html="toLocal('home_who_two')">
      </span>
      <span class="info-text" v-html="toLocal('home_who_three')"
        ></span
      >
    </div>
    <div class="image-main-container">
      <span class="image-title">{{toLocal('home_meet')}}</span>
      <div class="images-with-names" v-if="width > 1100">
        <div class="image-container">
          <img
            class="image"
            src="@/assets/images/Nijole.jpg"
            alt="third_image"
          />
          <span>Nijolė Steponkutė</span>
          <span><b v-html="toLocal('home_honorary') "></b></span>
        </div>
        <div class="image-container">
          <img
            class="image"
            src="@/assets/images/second.jpg"
            alt="second_image"
          />
          <span>Loreta Kelbauskaitė-Šerpytė</span>
          <span><b>{{toLocal('home_president')}}</b></span>
        </div>
        <div class="image-container">
          <img
            class="image"
            src="@/assets/images/Irina.jpg"
            alt="first_image"
          />
          <span>Irina Izgorodina</span>
          <span><b>{{toLocal('home_vice')}}</b></span>
        </div>
      </div>
      <div class="images-with-names" v-else>
        <div class="image-container">
          <img
            class="image"
            src="@/assets/images/second.jpg"
            alt="second_image"
          />
          <span>Loreta Kelbauskaitė-Šerpytė</span>
          <span><b>{{toLocal('home_president')}}</b></span>
        </div>
        <div class="image-container">
          <img
            class="image"
            src="@/assets/images/Nijole.jpg"
            alt="third_image"
          />
          <span>Nijolė Steponkutė</span>
          <span><b v-html="toLocal('home_honorary') "></b></span>
        </div>
        <div class="image-container">
          <img
            class="image"
            src="@/assets/images/Irina.jpg"
            alt="first_image"
          />
          <span>Irina Izgorodina</span>
          <span><b>{{toLocal('home_vice')}}</b></span>
        </div>
      </div>
    </div>
    <div class="sponsors-container">
      <span class="sponsors-title">{{toLocal('home_sponsors')}}</span>
      <div class="sponsors-boxes">
        <div class="sponsor" v-for="i in 12" :key="`spon_${i}`">
          <a target="_blank" :href="sponsorLinks[i - 1]">
            <img :src="require(`@/assets/images/spon_${i}.jpg`)" alt="sponsor"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import LtFlagSvg from "@/assets/icons/lt-flag.svg?inline";
//import UaFlagSvg from "@/assets/icons/ua-flag.svg?inline";
//import ArrowRightSvg from "@/assets/icons/arrow-right.svg?inline";
import HeaderComponent from "@/components/HeaderComponent.vue";
export default {
  name: "HomeView",
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      sponsorLinks: [
        "https://www.boell.de/en",
        "https://www.palmefonden.se/the-olof-palme-memorial-fund/",
        "https://www.womenalliance.org/",
        "https://op.europa.eu/en/publication-detail/-/publication/dedbc34e-fe90-11e8-a96d-01aa75ed71a1/language-en",
        "https://ec.europa.eu/commission/presscorner/detail/en/IP_10_1481",
        "https://dc.fes.de/about/friedrich-ebert-stiftung",
        "https://www.netherlandsandyou.nl/your-country-and-the-netherlands/ukraine/and-the-netherlands/matra-programme",
        "https://www.opensocietyfoundations.org/who-we-are/programs/womens-rights-program",
        "https://www.learnkey.lt/lt",
        "https://www.soroptimist-danmark.dk/",
        "https://www.esf.lt/",
        "https://www.esf.lt/veiklos-sritys/alternatyviu-investiciju-detektorius/1033"
      ],
    };
  },
  components: {
    HeaderComponent,
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    gotoProjects() {
      this.$router.push("/projects");
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
};
</script>
<style scoped lang="scss">
.home-page {
  width: 100%;
  height: 100%;
}
.home-container {
  display: flex;
  align-items: center;
}
.work-container {
  width: 85vw;
  background: #ffffff;
  border: 4px solid #f1e9e2;
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  margin-top: 40px;
  margin-bottom: 52px;
  .image-work-container{
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-bottom: 28px;
    @media(max-width: 1100px){
      gap: 20px;
    }
    img{
      max-width: 30%;
      object-fit: scale-down;
      max-height: 80px;
      @media(max-width: 1100px){
      max-width: 30%;
      }
    }
  }
  @media (max-width: 1100px) {
    margin-bottom: 0;
    margin: 0 28px;
    margin-top: 24px;
    padding-top: 20px;
  }
  .text-block {
    width: 80%;
    font-size: clamp(16px, 4vw, 20px);
    font-weight: 400;
    @media (max-width: 1100px) {
      width: 80%;
    }
  }
  .work-title {
    margin-top: 12px;
    font-weight: 600;
    font-size: clamp(24px, 4vw, 36px);
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;

    color: #625a54;
  }
  .separator-underline {
    height: 20px;
    width: 65%;
    border-bottom: 4px solid #f1e9e2;
    margin-bottom: 24px;
  }
  .flags {
    display: flex;
    svg {
      margin: 0 10px;
      width: clamp(44px, 5vw, 60px);
      height: 44px;

      margin-bottom: 4px;
    }
  }
  .text-separated {
    margin: 10px 0;
    font-size: clamp(16px, 4vw, 20px);
  }
  .mail-link {
    color: rgba(0, 0, 0, 1);
  }
  .brown-btn-container {
    background: #625a54;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px 24px;
    padding-right: 48px;
    color: #ffffff;
    margin-top: 22px;
    margin-bottom: 36px;
    font-size: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: #38322d;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    }
    .arrow {
      position: absolute;
      right: 10%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .gray-bottom {
    background: #f1e9e2;
    width: 100%;
    font-size: clamp(14px, 4vw, 16px);
    padding: 12px 0;
  }
}
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 74px;
  padding: 0 28px;
  .info-title {
    margin: 40px 0;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: clamp(32px, 4vw, 48px);
    line-height: 72px;
    @media (max-width: 1100px) {
      margin-bottom: 10px;
    }
  }
  .info-text {
    width: 62%;
    text-align: center;
    margin: 10px 0;
    @media (max-width: 1100px) {
      width: 92%;
      text-align: start;
    }
  }
}
.image-main-container {
  width: 100%;
  background: #f1e9e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  .image-title {
    font-weight: 400;
    font-size: clamp(24px, 4vw, 32px);
    line-height: 48px;
    margin-top: 60px;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: 1100px) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .images-with-names {
    display: flex;
    gap: 64px;
    flex-wrap: wrap;
    justify-content: center;
    @media(max-width:1100px){
      flex-direction: column;
    }
    .image-container {
      display: flex;
      flex-direction: column;
      img {
        border-radius: 8px;
        margin-bottom: 12px;
        max-width: 280px;
        max-height: 280px;
      }
      span {
        margin: 4px 0;
      }
    }
  }
}
.sponsors-container {
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  @media (max-width: 1100px) {
    padding-bottom: 20px;
  }
  .sponsors-title {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 48px;
  }
  .sponsors-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 65px;
    width: 92%;
    @media (max-width: 1100px) {
      flex-direction: column;
      gap: 0;
    }
    .sponsor {
      display: flex;
      justify-content: center;
      @media (max-width: 1100px) {
        width: 100%;
        padding: 28px 0;
        &:not(:last-child) {
          border-bottom: solid #f1e9e2;
        }
      }
      a {
        @media (max-width: 1100px) {
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          object-fit: scale-down;
          width: 95%;
        }
      }
    }
  }
}
</style>
