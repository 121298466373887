import Vue from "vue";
import Router from "vue-router";
import HomeView from "./views/Home.vue";
import NewsView from "./views/NewsView.vue";
import ProjectsView from "./views/ProjectsView.vue";
import ConferencesView from "./views/ConferencesView.vue";
import MembershipView from "./views/MembershipView.vue";
import HistoryView from "./views/HistoryView.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/news",
      name: "news",
      component: NewsView,
    },
    {
      path: "/projects",
      name: "projects",
      component: ProjectsView,
    },
    {
      path: "/conferences",
      name: "conferences",
      component: ConferencesView,
    },
    {
      path: "/membership",
      name: "membership",
      component: MembershipView,
    },
    { path: "/history", name: "history", component: HistoryView },

    {
      path: "/:lang",
      name: "lang-home",
      component: HomeView,
    },
    {
      path: "/:lang/news",
      name: "lang-news",
      component: NewsView,
    },
    {
      path: "/:lang/projects",
      name: "lang-projects",
      component: ProjectsView,
    },
    {
      path: "/:lang/conferences",
      name: "lang-conferences",
      component: ConferencesView,
    },
    {
      path: "/:lang/membership",
      name: "lang-membership",
      component: MembershipView,
    },
    { path: "/:lang/history", name: "lang-history", component: HistoryView },
  ],
});
