<template>
  <div class="history-page">
    <HeaderComponent
      titleText="history_title"
      imageSrc="musu-istorija-BG.jpg"
    />
    <span class="history-title"
      ><b>
        {{toLocal('history_main_title')}}</b
      ></span
    >
    <div class="text-block">
      <div class="history-text">
        {{toLocal('history_main_title')}}
      </div>
      <div class="history-text" v-html="toLocal('history_desc_two')">
      </div>
      <div class="history-text" v-html="toLocal('history_desc_three')">
      </div>
    </div>
    <div class="history-grid" v-if="width >= 481">
      <img
        class="block"
        src="@/assets/images/history/1x1/foto1.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto2.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto3.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto4.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto21.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto6.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto7.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto12.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto8.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto9.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto15.jpg"
        alt="history-foto"
      />
      <img
        class="wide"
        src="@/assets/images/history/16x9/foto18.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto11.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto13.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto14.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto19.jpg"
        alt="history-foto"
      />
      <img
        class="block"
        src="@/assets/images/history/1x1/foto5.jpg"
        alt="history-foto"
      />
    </div>
    <div class="history-sliders" v-else>
      <Swiper
        class="swiper"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1.6"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto5.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto1.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto8.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
      <Swiper
        class="swiper"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1.6"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto9.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto11.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto13.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
      <Swiper
        class="swiper"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1.6"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto14.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto19.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/1x1/foto21.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
      <Swiper
        class="swiper-big"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto2.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto3.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
      <Swiper
        class="swiper-big"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto4.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto6.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
      <Swiper
        class="swiper-big"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto7.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto12.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
      <Swiper
        class="swiper-big"
        :freeMode="true"
        :slidesOffsetBefore="-170"
        :loop="true"
        :slides-per-view="1"
        :space-between="24"
      >
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto15.jpg" alt="conf_image"
        /></SwiperSlide>
        <SwiperSlide
          ><img src="@/assets/images/history/16x9/foto18.jpg" alt="conf_image"
        /></SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  name: "HistoryView",
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  components: { HeaderComponent, Swiper, SwiperSlide },
};
</script>

<style lang="scss" scoped>
.history-page {
  display: flex;
  flex-direction: column;
}
.history-title {
  margin-top: 40px;
  font-size: 20px;
  @media (max-width: 1100px) {
    font-size: 18px;
    width: 70%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
}
.text-block {
  margin: 44px 5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1100px) {
    text-align: start;
    margin-top: 28px;
  }
}
.history-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  column-gap: 32px;
  padding: 0 5%;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 85%;
    border-radius: 8px;
  }
  .wide {
    grid-column: span 2;
  }
  .block {
    grid-column: span 1;
  }
}
.history-sliders {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 36px;
}
.swiper {
  width: 100%;
  height: 236px;
  img {
    height: 236px;
    border-radius: 8px;
  }
}
.swiper-big {
  width: 100%;
  height: 236px;
  img {
    height: 236px;
    max-width: 100vw;
    border-radius: 8px;
  }
}
</style>
