<template>
  <div class="nav-container">
    <a :href="'/'+$store.state.lang.toLowerCase() + '/'">
      <div class="logo">
        <img
          v-if="width > 1100 && $store.state.lang == 'LT'"
          class="logo-desktop"
          src="@/assets/images/sponsors/_LMD-logo.png"
          alt="site_logo"
        />
        <img
          v-else-if="width > 1100 && $store.state.lang == 'EN'"
          class="logo-mobile"
          src="@/assets/images/sponsors/_LMD-logo-en.png"
          alt="site_logo"
        />
        <img
          v-else
          class="logo-mobile"
          src="@/assets/images/sponsors/_LMD-logo-pav.png"
          alt="site_logo"
        />
      </div>
    </a>
    <div class="btn-container" v-if="width > 1100">
      <span @click="setActive(0)" :class="{ active: activeBtn == 0 }"
        >{{toLocal('nav_start')}}</span
      >
      <span @click="setActive(1)" :class="{ active: activeBtn == 1 }"
        >{{toLocal('nav_news')}}</span
      >
      <span @click="setActive(2)" :class="{ active: activeBtn == 2 }"
        >{{toLocal('nav_projects')}}</span
      >
      <span @click="setActive(3)" :class="{ active: activeBtn == 3 }"
        >{{toLocal('nav_conferences')}}</span
      >
      <span @click="setActive(4)" :class="{ active: activeBtn == 4 }"
        >{{toLocal('nav_membership')}}</span
      >
      <span @click="setActive(5)" :class="{ active: activeBtn == 5 }"
        >{{ toLocal('nav_history') }}</span
      >
      <div class="lang-container">
        <div class="flag"></div>
        <span @click="changeLang('lt')" class="faded" :class="{activeFlag:$store.state.lang == 'LT'}">LT</span>-<span @click="changeLang('en')" class="faded" :class="{activeFlag:$store.state.lang == 'EN'}">EN</span>
      </div>
    </div>
    <div
      class="nav-burger"
      @click="navBarExpanded = !navBarExpanded"
      v-else-if="width < 1100"
    >
      <BurgerSvg />
    </div>
    <div
      class="nav-menu-cover"
      :class="{ active: navBarExpanded }"
      v-if="width < 1100"
    >
      <div class="nav-menu">
        <div class="close-menu" @click="navBarExpanded = false">
          <CloseIconSvg />
        </div>
        <span @click="setActive(0)" :class="{ active: activeBtn == 0 }"
          >{{toLocal('nav_start')}}</span
        >
        <span @click="setActive(1)" :class="{ active: activeBtn == 1 }"
          >{{toLocal('nav_news')}}</span
        >
        <span @click="setActive(2)" :class="{ active: activeBtn == 2 }"
          >{{toLocal('nav_projects')}}</span
        >
        <span @click="setActive(3)" :class="{ active: activeBtn == 3 }"
          >{{toLocal('nav_conferences')}}</span
        >
        <span @click="setActive(4)" :class="{ active: activeBtn == 4 }"
          >{{toLocal('nav_membership')}}</span
        >
        <span @click="setActive(5)" :class="{ active: activeBtn == 5 }"
          >{{ toLocal('nav_history') }}</span
        >
        <div class="lang-container-mobile">
          <div class="flag"></div>
          <span @click="changeLang('lt')" class="faded" :class="{activeFlag:$store.state.lang == 'LT'}">LT</span>-<span @click="changeLang('en')" class="faded" :class="{activeFlag:$store.state.lang == 'EN'}">EN</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BurgerSvg from "@/assets/icons/burger.svg?inline";
import CloseIconSvg from "@/assets/icons/close-icon.svg?inline";
export default {
  name: "NavBar",
  data() {
    return {
      activeBtn: 0,
      routes: [
        `/`,
        `/news`,
        `/projects`,
        `/conferences`,
        `/membership`,
        `/history`,
      ],
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      navBarExpanded: false,
    };
  },
  components: {
    BurgerSvg,
    CloseIconSvg,
  },
  methods: {
    changeLang(lang){
      this.$store.state.lang = lang.toUpperCase();
      this.$router.push('/'+lang+'/');
      this.activeBtn = 0;
      this.navBarExpanded = false;
    },
    setActive(activeNum) {
      if (this.activeBtn != activeNum) {
        this.activeBtn = activeNum;
        this.navBarExpanded = false;
        this.$router.push('/'+this.$store.state.lang.toLowerCase()+this.routes[this.activeBtn]);
      }
    },
    setExternalActive(path) {
      this.routes.forEach((route, index) => {
        if (route == `/${path}`) {
          this.activeBtn = index;
        }
      });
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    if (window.location.pathname != "/") {
      this.routes.forEach((route, index) => {
        if (route == window.location.pathname) {
          this.activeBtn = index;
        }
      });
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  watch: {
    $route(to) {
      if (to.name != "home") {
        this.setExternalActive(to.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-container {
  position: absolute;
  top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 5%;
  z-index: 99;
  @media (max-width: 1100px) {
    flex-direction: row-reverse;
    margin-top: 28px;
    top: 0;
  }
  .logo-desktop {
    width: 182px;
  }
  .logo-mobile {
    width: 70%;
  }
  .btn-container {
    display: flex;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      user-select: none;
      cursor: pointer;
      transition: 0.25s;
      margin: 0 10px;
      &.active {
        font-weight: 600;
        border-bottom: 1px solid #000000;
        cursor: default;
      }
    }
  }
  .nav-burger {
    cursor: pointer;
  }
  .nav-menu-cover {
    position: fixed;
    height: 100vh;
    z-index: 99;
    width: 100%;
    left: 0;
    margin-left: -100%;
    background: rgba(0, 0, 0, 0);
    transition: margin 0.25s, background 0s;
    &.active {
      margin-left: 0;
      background: rgba(0, 0, 0, 0.3);
      transition: margin 0.25s, background 0.5s;
    }
    top: 0;

    .nav-menu {
      height: 100%;
      width: 80%;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 32px;
      padding-top: 44px;
      box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.2);
      position: relative;
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        user-select: none;
        cursor: pointer;
        transition: 0.25s;
        margin: 16px 0;
        &.active {
          font-weight: 700;
          border-bottom: 1px solid #000000;
          cursor: default;
        }
      }
      .close-menu {
        position: absolute;
        right: 24px;
        cursor: pointer;
        top: 24px;
      }
    }
  }
}
.faded{
  opacity: 0.3;
}
.activeFlag{
  font-weight: bold;
  opacity: 1;
}
.lang-container{
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.lang-container-mobile{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
