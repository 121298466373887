<template>
  <div class="projects-page" id="project">
    <HeaderComponent
      imageSrc="projektai-BG.jpg"
      titleText="projects_title"
    />
    <ArrowUpSvg
      class="arrow-up"
      v-if="width <= 1100 && hasScrolledBottom"
      @click="scrollToTop"
    />
    <div class="projects-container">
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_job')">
        </span>
        <div v-if="width > 1100" class="btn-row">
          <a class="btn" href="./plakatas-noriu-darbo-UA-2.pdf" download>
            {{toLocal('projects_download_ua')}} <UaFlagSvg class="flag" />
          </a>
          <a class="btn" href="./plakatas-noriu-darbo-LT-2.pdf" download>
            {{toLocal('projects_download_lt')}} <LtFlagSvg class="flag" />
          </a>
        </div>

        <span class="text">
          {{toLocal('projects_job_desc')}}
        </span>
        <span class="text" v-html="toLocal('projects_job_desc_two')"></span
        >
        <span class="text">
          {{toLocal('projects_job_desc_three')}}
        </span>
        <span class="text" v-html="toLocal('projects_job_desc_four')">
        </span>
        <div v-if="width <= 1100" class="btn-row">
          <a class="btn" href="./plakatas-noriu-darbo-UA-2.pdf" download>
            {{toLocal('projects_download_ua')}} <UaFlagSvg class="flag" />
          </a>
          <a class="btn" href="./plakatas-noriu-darbo-LT-2.pdf" download>
            {{toLocal('projects_download_lt')}} <LtFlagSvg class="flag" />
          </a>
        </div>
        <div class="image-row">
          <img src="@/assets/images/projects/2.jpg" alt="project_img" />
          <img src="@/assets/images/projects/1.jpg" alt="project_img" />
        </div>
        <span class="text">
          <b
            >{{projects_hope}}</b
          >
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_society')"
          ></span
        >
        <span class="text">
          {{toLocal('projects_society_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_future')"
          ></span
        >
        <span class="text">
          {{toLocal('projects_future_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_psichological')"
          ></span
        >
        <span class="text">
          {{toLocal('projects_psichological_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_specialists')"
          ></span
        >
        <span class="text">
          {{toLocal('project_specialists_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('project_dialogue')"
          ></span
        >
        <span class="text">
          {{toLocal('project_dialogue_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_bureau')"
          ></span
        >
        <span class="text">
          {{toLocal('projects_bureau_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title" v-html="toLocal('projects_information')"
          ></span
        >
        <span class="text">
          {{toLocal('projects_information_desc')}}
        </span>
      </div>
      <div class="project-block">
        <span class="project-title text" v-html="toLocal('projects_consultation')"
          ></span
        >
        <span class="text">
          {{toLocal('projects_consultation_desc')}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import LtFlagSvg from "@/assets/icons/lt-flag.svg?inline";
import UaFlagSvg from "@/assets/icons/ua-flag.svg?inline";
import ArrowUpSvg from "@/assets/icons/arrow-up.svg?inline";

export default {
  name: "ProjectsView",
  components: { HeaderComponent, LtFlagSvg, UaFlagSvg, ArrowUpSvg },
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      hasScrolledBottom: false,
    };
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    onScroll() {
      if (window.pageYOffset >= 600) {
        this.hasScrolledBottom = true;
      } else {
        this.hasScrolledBottom = false;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    window.addEventListener("scroll", this.onScroll, true);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
    window.removeEventListener("scroll", this.onScroll, true);
  },
};
</script>

<style lang="scss" scoped>
.projects-page {
  position: relative;
  .arrow-up {
    position: fixed;
    bottom: 24px;
    right: 28px;
    z-index: 89;
    transition: 0.25s;
  }
}
.projects-container {
  display: flex;
  flex-direction: column;
  margin: 40px 10%;
}
.project-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0;
  &:not(:last-child) {
    border-bottom: 2px solid #f1e9e2;
  }
  &:first-child {
    gap: 20px;
  }
  .project-title {
    margin-bottom: 24px;
    font-size: 20px;
    text-align: start;
    @media (max-width: 1100px) {
      font-weight: 700;
      width: 92%;
    }
  }
  .text {
    width: 100%;
    text-align: start;
    &:not(.project-title) {
      margin-top: 10px 0;
    }
  }
  .btn-row {
    display: flex;
    gap: 36px;
    margin-bottom: 40px;
    @media (max-width: 1100px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 36px;
    }
    .btn {
      padding: 8px 24px;
      background: #f1e9e2;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      text-decoration: none;
      color: #000000;
      transition: 0.25s;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      @media (max-width: 1100px) {
        padding: 8px 16px;
      }
      .flag {
        width: 24px;
        height: 17px;
      }
      &:hover {
        background: #cbb6a4;
      }
    }
  }
  .image-row {
    width: 100%;
    display: flex;
    gap: 24px;
    @media (max-width: 1100px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 30%;
      border-radius: 8px;
      @media (max-width: 1100px) {
        width: 100%;
      }
    }
  }
}
</style>
