import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import methods from './global/methods.js'
import store from './store';
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'


Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueCookies, { expires: '7d'})
Vue.use(VueGtag, {
  config: { 
    id: "G-5K9YQDT5KE",
  },
  bootstrap: false
}, router);

Vue.config.productionTip = false;


Vue.mixin(methods);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

